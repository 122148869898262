<template>
  <div class="products">
    <div class="title">
      <img :src="$t('products.topTitle')" alt="">
    </div>
    <div class="mid">
      <a-space direction="vertical" :size="100">
        <div class="mid1">
          <a href="https://myaz.com.au/search?term=medowry" target="_blank">
            <img :src="$t('products.mid1')" alt="">
          </a>
        </div>
        <div class="mid2">
          <img :src="$t('products.mid2')" alt="">
        </div>
        <div class="mid3">
          <a href="https://myaz.com.au/product/62578634b1b96277ca69ae00" target="_blank"><img :src="$t('products.mid3')" alt=""></a>
        </div>
        <div class="mid4">
          <img :src="$t('products.mid4')" alt="">
        </div>
        <div class="mid4">
          <a href="https://myaz.com.au/product/63368277a0f15d6aaafbee67" target="_blank">
            <img :src="$t('products.mid5')" alt="">
          </a>
        </div>
        <div class="mid4">
          <img :src="$t('products.mid6')" alt="">
        </div>
      </a-space>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue'

onMounted(() => {
  window.scrollTo(0, 0)
})
</script>

<style lang="less" scoped>
// .products {
//   background-image: url("https://assets-img.myazstore.com/medwory/bg.png");
//   background-size: 100% auto;
// }
.title {
  // padding-left: 12.76%;
  padding-top: 101px;
  padding-bottom: 149px;

  img {
    width: 325px;
  }
}
.mid {
  padding-bottom: 100px;
  // .mid1 {
  //   padding: 0 0 0 18.125%;
  // }

  // .mid2, .mid4 {
  //   padding: 0 12.5% ;
  // }

  // .mid3 {
  //   padding: 0 6.125% 0 0;
  // }

  img {
    width: 100%;
    display: block;
  }
}

@media only screen and (max-width: 1440px) {
  .products {
    padding-left: 24px;
    padding-right: 24px;
  }
}
</style>
